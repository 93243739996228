import type BlogPost from '~/types/blogPost'
import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { Locale } from '~/types/locale'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export function provideGetBlogPosts(coreFetch: CoreFetch) {
  return async function (
    language: Locale,
    fallbackLocale: Locale = 'en',
  ): Promise<BlogPost[]> {
    try {
      const { results } = await coreFetch.$get<PaginatedApiResponse<BlogPost>>(
        `/welcome/blog-post/`,
        {
          query: {
            limit: 3,
            lang: language,
            fallback_lang: fallbackLocale,
          },
        },
      )
      return results || []
    } catch (_) {
      return []
    }
  }
}
