
import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { Locale } from '~/types/locale'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'
import type SuccessStory from '~/types/successStory'

export function provideGetSuccessStories(coreFetch: CoreFetch) {
  return async function fetchSuccessStories(
    locale: Locale,
    fallbackLocale: Locale = 'en',
  ): Promise<SuccessStory[]> {
    try {
      const { results } = await $coreFetch.$get<
        PaginatedApiResponse<SuccessStory>
      >(`/welcome/success-story/`, {
        query: {
          limit: 3,
          lang: locale,
          fallback_lang: fallbackLocale,
        },
      })

      return results ?? []
    } catch (_) {
      return []
    }
  }
}
